import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { ProjectDependencies } from '@portal-core/projects/models/project-dependencies.model';
import { ProjectReportScanStatusWithLatestNotificationId } from '@portal-core/projects/models/project-report-scan-status-with-latest-notification-id.model';
import { Project } from '@portal-core/projects/models/project.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getProjectById$(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}`);
  }

  @SharePendingRequest()
  getProjectsByIds$(projectIds: number[]): Observable<Project[]> {
    return this.http.post<Project[]>(`${this.apiService.centralApiBaseUri}/api/projects/getBatchProjectsByIds`, projectIds);
  }

  @SharePendingRequest()
  getProjectsPageByLicenseId$(licenseId: number, filter: PageFilter, maxIncludes?: number): Observable<Page<Project>> {
    return this.http.post<Page<Project>>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/getMyProjectsByLicenseList${typeof maxIncludes === 'number' ? '?maxIncludes=' + maxIncludes : ''}`, filter);
  }

  @SharePendingRequest()
  getReviewProjectsPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<Project>> {
    return this.http.post<Page<Project>>(`${this.apiService.centralApiBaseUri}/api/Reviews/Projects/${licenseId}`, filter);
  }

  @SharePendingRequest()
  checkProjectById$(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${this.apiService.centralApiBaseUri}/api/projects/userCheck/${projectId}`);
  }

  @SharePendingRequest()
  getProjectListItemsByLicenseId$(licenseId: number): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/getProjectsList`);
  }

  @SharePendingRequest()
  getMyProjectListItemsByLicenseId$(licenseId: number): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/getMyProjectsByLicenseLite`).pipe(
      map(projects => projects.filter(project => project.UserHasAccess))
    );
  }

  updateProject$(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.apiService.centralApiBaseUri}/api/Projects/updateProjectSettings`, project);
  }

  updateProjectTeamsAndUsers$(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.apiService.centralApiBaseUri}/api/Projects/updateProjectTeamsAndUsers`, project);
  }

  @SharePendingRequest()
  getProjectsList$(licenseId: number): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/getProjectsList`);
  }

  @SharePendingRequest()
  getProjectsByLicenseIdAndUserId$(licenseId: number, userId: string): Observable<Project[]> {
    return this.http.get<Page>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/user/${userId}/getUserProjects`)
      .pipe(map(projectsPage => projectsPage.Items));
  }

  @SharePendingRequest()
  getProjectReportScanStatus$(projectId: number): Observable<ProjectReportScanStatusWithLatestNotificationId> {
    return this.http.get<ProjectReportScanStatusWithLatestNotificationId>(`${this.apiService.centralApiBaseUri}/api/analyzer/scanstatus/${projectId}`);
  }

  putProjectStatus$(projectId: number, status: ProjectStatus): Observable<any> {
    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/changeStatus`, { Status: status });
  }

  analyzeProject$(projectId: number): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/analyzer/scanproject/${projectId}`, null);
  }

  // Using POST method instead of DELETE because Angular http.delete doesn't support request body https://angular.io/api/common/http/HttpClient
  bulkDeleteProjects$(projectIds: number[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/projects/bulkDeleteProjects`, projectIds);
  }

  bulkUpdateProjectStatus$(status: ProjectStatus, projectIds: number[]): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/projects/bulkUpdateStatus/${status}`, projectIds);
  }

  @SharePendingRequest()
  getProjectDependencies$(projectId: number): Observable<ProjectDependencies> {
    return this.http.get<ProjectDependencies>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/dependencies`);
  }
}
