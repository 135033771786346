<form class="mc-spring-board" (ngSubmit)="onSubmit(userSettingsForm)" [formGroup]="userSettingsForm">
  <div class="mc-spring-board-body">
    <div class="mc-form-field">
      <mc-image-picker placeholder="Avatar" formControlName="Avatar" color="primary-vivid"></mc-image-picker>
      <mat-error class="mc-form-field-subscript">
        <mc-form-control-error-message [form]="userSettingsForm" controlName="Avatar" type="mc_avatar_image"></mc-form-control-error-message>
      </mat-error>
    </div>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>First Name</mat-label>
      <input matInput type="text" formControlName="FirstName" placeholder="First Name" required>
      <mat-error>
        <mc-form-control-error-message [form]="userSettingsForm" controlName="FirstName" type="mc_user_first_name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Last Name</mat-label>
      <input matInput type="text" formControlName="LastName" placeholder="Last Name" required>
      <mat-error>
        <mc-form-control-error-message [form]="userSettingsForm" controlName="LastName" type="mc_user_last_name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Initials (no avatar)</mat-label>
        <input matInput type="text" formControlName="Initials" placeholder="Initials (no avatar)" required>
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="Initials" type="mc_user_initials"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="Title" placeholder="Title">
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="Title" type="mc_user_title"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Phone</mat-label>
        <input matInput type="tel" formControlName="Phone" placeholder="Phone">
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="Phone" type="mc_phone"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Cell Phone</mat-label>
        <input matInput type="tel" formControlName="CellPhone" placeholder="Cell Phone">
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="CellPhone" type="mc_phone"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Location</mat-label>
        <input matInput type="text" formControlName="Location" placeholder="Location">
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="Location" type="mc_user_location"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Department</mat-label>
        <input matInput type="text" formControlName="Department" placeholder="Department">
        <mat-error>
          <mc-form-control-error-message [form]="userSettingsForm" controlName="Department" type="mc_user_department"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Secondary Email</mat-label>
      <input matInput type="email" formControlName="SeconderyEmail" placeholder="Secondary Email">
      <mat-error>
        <mc-form-control-error-message [form]="userSettingsForm" controlName="SeconderyEmail" type="mc_email"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div *ngIf="showIdpLogout" class="mc-form-field">
      <mat-checkbox color="primary-vivid" formControlName="IdpLogout">Log out of single sign-on identity provider when logging out of Central</mat-checkbox>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button *ngIf="showCancelButton" type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="userSettingsForm.pristine || userSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>
