<form class="mc-spring-board" (ngSubmit)="onSubmit(passwordForm)" [formGroup]="passwordForm" #formDirective="ngForm">
  <div class="mc-spring-board-body">
    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input matInput type="password" autocomplete="current-password" formControlName="currentPassword" placeholder="Current Password" required>
      <mat-error>
        <mc-form-control-error-code [form]="passwordForm" controlName="currentPassword" type="mc_password"></mc-form-control-error-code>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input matInput type="password" autocomplete="new-password" formControlName="newPassword" placeholder="New Password" required>
      <mat-error>
        <mc-form-control-error-code [form]="passwordForm" controlName="newPassword" type="mc_password"></mc-form-control-error-code>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input matInput type="password" autocomplete="new-password" formControlName="confirmPassword" placeholder="Confirm Password" required [formGroup]="passwordForm">
      <mat-error>
        <mc-form-control-error-code [form]="passwordForm" controlName="confirmPassword" type="mc_confirm_password"></mc-form-control-error-code>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button *ngIf="showCancelButton" type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="passwordForm.pristine || passwordForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>
