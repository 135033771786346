import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { RouterModule } from '@angular/router';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { LicenseAvatarModule } from '@portal-core/licenses/components/license-avatar/license-avatar.module';
import { LicenseAsyncModule } from '@portal-core/licenses/pipes/license-async/license-async.module';
import { ProjectChecklistLinkModule } from '@portal-core/project-checklists/pipes/project-checklist-link/project-checklist-link.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { ReviewFileAsyncModule } from '@portal-core/reviews/review-files/pipes/review-file-async/review-file-async.module';
import { ReviewPackageLinkModule } from '@portal-core/reviews/review-packages/pipes/review-package-link/review-package-link.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SiteAsyncModule } from '@portal-core/sites/pipes/site-async/site-async.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { TaskBoardAvatarModule } from '@portal-core/task-boards/components/task-board-avatar/task-board-avatar.module';
import { TaskBoardAsyncModule } from '@portal-core/task-boards/pipes/task-board-async/task-board-async.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamAsyncModule } from '@portal-core/teams/pipes/team-async/team-async.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { NotificationMessageTagComponent } from './notification-message-tag.component';

@NgModule({
  declarations: [
    NotificationMessageTagComponent
  ],
  imports: [
    CommonModule,
    InlineButtonModule,
    LicenseAsyncModule,
    LicenseAvatarModule,
    LicenseUserAvatarModule,
    LicenseUserForLicenseAsyncModule,
    MatDialogModule,
    MatIconModule,
    ProjectAvatarModule,
    ProjectAsyncModule,
    ProjectChecklistLinkModule,
    ReviewFileAsyncModule,
    ReviewFileAvatarModule,
    ReviewPackageLinkModule,
    RouterModule,
    TargetAvatarModule,
    SiteAsyncModule,
    SiteAvatarModule,
    TaskBoardAsyncModule,
    TaskBoardAvatarModule,
    TeamAvatarModule,
    TeamAsyncModule
  ],
  exports: [
    NotificationMessageTagComponent
  ]
})
export class NotificationMessageTagModule { }
