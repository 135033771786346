import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { AnyBranchId } from '@portal-core/branches/constants/any-branch-id.constant';
import { BranchesService } from '@portal-core/branches/services/branches.service';
import { BuildPickerValue } from '@portal-core/builds/types/build-picker-value.type';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { TargetsService } from '@portal-core/targets/services/targets.service';
import { AutocompleteInputComponent } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.component';
import { isEqual } from 'lodash';
import { Observable, distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'mc-build-picker',
  templateUrl: './build-picker.component.html',
  styleUrls: ['./build-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildPickerComponent implements OnInit {
  /** The theme color of the build picker. */
  @Input() color: ThemePalette;

  /** The license id that the build should be picked from. */
  @Input() licenseId: number;

  /** The current value of the build picker. */
  @Input()
  get value(): BuildPickerValue {
    return this._value;
  }
  set value(value: BuildPickerValue) {
    if (!isEqual(this._value, value)) {
      this._value = value;
      this.valueChange.emit(value);
    }
  }
  private _value: BuildPickerValue;

  @Output() clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() done: EventEmitter<void> = new EventEmitter<void>();

  /** Emits when the value changes (either due to user input or programmatic change). */
  @Output() valueChange: EventEmitter<BuildPickerValue> = new EventEmitter<BuildPickerValue>();

  /** A reference to the projects autocomplete component. */
  @ViewChild(AutocompleteInputComponent, { static: true }) projectsAutocompleteInput: AutocompleteInputComponent;

  @PropertyObservable('value') value$: Observable<BuildPickerValue>;

  ProcessState: typeof ProcessState = ProcessState;

  buildDisabled$: Observable<boolean>;
  buildLabel$: Observable<string>;
  branchDisabled$: Observable<boolean>;
  branchLabel$: Observable<string>;
  targetDisabled$: Observable<boolean>;
  targetLabel$: Observable<string>;

  /** Returns the value for mc-branches-autocomplete. */
  get branchValue(): string[] {
    let branchId: string;

    if (typeof this.value?.branchName === 'string') {
      branchId = this.branchesService.buildBranchId(this.value.projectId, this.value.branchName);
    } else if (typeof this.value?.projectId === 'number' && typeof this.value?.targetPath === 'string') {
      // Only default to the "Any Branch" option if a project id and target path have been picked already
      branchId = AnyBranchId;
    }

    return branchId ? [branchId] : null;
  }

  /** Returns the value for mc-builds-autocomplete. */
  get buildValue(): number[] {
    return typeof this.value?.buildId === 'number' ? [this.value.buildId] : null;
  }

  /** Returns the value for mc-projects-autocomplete. */
  get projectValue(): number[] {
    return typeof this.value?.projectId === 'number' ? [this.value.projectId] : null;
  }

  /** Returns the value for mc-targets-autocomplete. */
  get targetValue(): string[] {
    return typeof this.value?.targetPath === 'string' ? [this.targetsService.buildTargetId(this.value.projectId, this.value.targetPath)] : null;
  }

  constructor(private branchesService: BranchesService, private targetsService: TargetsService) { }

  ngOnInit() {
    // Create an observable for whether the build control is disabled
    this.buildDisabled$ = this.value$.pipe(
      map(value => value?.targetPath),
      distinctUntilChanged(),
      map(targetPath => typeof targetPath !== 'string')
    );

    // Create an observable for the build control's label
    this.buildLabel$ = this.value$.pipe(
      map(value => value?.targetPath),
      distinctUntilChanged(),
      map(targetPath => typeof targetPath === 'string' ? 'Build' : 'Please select a target first')
    );

    // Create an observable for whether the branch control is disabled
    this.branchDisabled$ = this.value$.pipe(
      map(value => value?.targetPath),
      distinctUntilChanged(),
      map(targetPath => typeof targetPath !== 'string')
    );

    // Create an observable for the branch control's label
    this.branchLabel$ = this.value$.pipe(
      map(value => value?.targetPath),
      distinctUntilChanged(),
      map(targetPath => typeof targetPath === 'string' ? 'Branch' : 'Please select a target first')
    );

    // Create an observable for whether the target control is disabled
    this.targetDisabled$ = this.value$.pipe(
      map(value => value?.projectId),
      distinctUntilChanged(),
      map(projectId => typeof projectId !== 'number')
    );

    // Create an observable for the target control's label
    this.targetLabel$ = this.value$.pipe(
      map(value => value?.projectId),
      distinctUntilChanged(),
      map(projectId => typeof projectId === 'number' ? 'Target' : 'Please select a project first')
    );
  }

  /** Handles the branch selected event to update the build picker value. */
  onBranchValueChanged(branchIds: string[]) {
    if (!isEqual(branchIds, this.branchValue)) {
      const branchId = branchIds?.length > 0 ? branchIds[0] : null;
      this.patchValue({
        branchName: branchId !== AnyBranchId ? this.branchesService.getItemById(branchIds[0])?.Name : null
      });
    }
  }

  /** Handles the build selected event to update the build picker value. */
  onBuildValueChanged(buildIds: number[]) {
    if (!isEqual(buildIds, this.buildValue)) {
      this.patchValue({
        buildId: buildIds?.length > 0 ? buildIds[0] : null
      });
    }
  }

  /** Handles the project change event to update the build picker value. */
  onProjectValueChanged(projectIds: number[]) {
    if (!isEqual(projectIds, this.projectValue)) {
      this.patchValue({
        projectId: projectIds?.length > 0 ? projectIds[0] : null
      });
    }
  }

  /** Handles the target selected event to update the build picker value. */
  onTargetValueChanged(targetPathIds: string[]) {
    if (!isEqual(targetPathIds, this.targetValue)) {
      this.patchValue({
        targetPath: targetPathIds?.length > 0 ? this.targetsService.getItemById(targetPathIds[0])?.Path : null
      });
    }
  }

  onClearFilterClicked() {
    this.patchValue({
      projectId: null,
      branchName: null,
      targetPath: null,
      buildId: null
    });

    this.clear.emit();
  }

  onDoneClicked() {
    this.done.emit();
  }

  closePopups() {
    this.projectsAutocompleteInput?.close();
  }

  /** Gives focus to the build picker. */
  focus() {
    this.projectsAutocompleteInput.focus();
  }

  /**
   * Updates the value of the build picker. If a property changes then the properties that depend on it are nulled out.
   * @param patchValue A partial BuildPickerValue with the properties to update.
   */
  patchValue(patchValue: Partial<BuildPickerValue>) {
    const newValue: BuildPickerValue = {
      ...this.value,
      ...patchValue
    };

    // If a property was changed then unset the properties that depend on it
    if (newValue.projectId !== this.value?.projectId) {
      newValue.targetPath = null;
      newValue.branchName = null;
      newValue.buildId = null;
    } else if (newValue.targetPath !== this.value?.targetPath) {
      newValue.branchName = null;
      newValue.buildId = null;
    } else if (newValue.branchName !== this.value?.branchName) {
      newValue.buildId = null;
    }

    this.value = newValue;
  }

  setValue(value: BuildPickerValue) {
    const newValue: BuildPickerValue = {
      projectId: null,
      targetPath: null,
      branchName: null,
      buildId: null,
      ...value
    };

    // If a property was changed then unset the properties that depend on it
    if (typeof newValue.projectId !== 'number') {
      newValue.branchName = null;
      newValue.targetPath = null;
      newValue.buildId = null;
    } else if (!newValue.targetPath) {
      newValue.branchName = null;
      newValue.buildId = null;
    } else if (!newValue.branchName) {
      newValue.buildId = null;
    }

    this.value = newValue;
  }
}
