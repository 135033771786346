import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { basename, dirname, extname } from '@common/util/path';
import { StringService } from '@portal-core/general/services/string.service';
import { TargetFileExtension } from '@portal-core/project-files/constants/file-extensions.constant';
import { Project } from '@portal-core/projects/models/project.model';

import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-target-avatar',
  templateUrl: './target-avatar.component.html',
  styleUrls: ['./target-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetAvatarComponent extends AvatarBase {
  @Input() branchName?: string;
  @Input() project?: Project;
  @HostBinding('class.mc-target-avatar-show-path')
  @Input() showPath?: boolean = false;
  @Input() targetPath: string;

  get targetBasename(): string {
    return basename(this.targetPath, `.${TargetFileExtension}`);
  }

  get targetDirname(): string {
    if (typeof this.targetPath === 'string') {
      // Trim leading 'project/targets/' from the path since all targets are in that folder
      return dirname(this.targetPath.replace(/^project\/targets\//i, ''), true);
    }
  }

  get targetExtname(): string {
    return extname(this.targetPath);
  }

  constructor(private stringService: StringService) {
    super();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.targetPath;
  }

  encodeURIComponent(component: string): string {
    return this.stringService.encodeURIComponent(component);
  }
}
