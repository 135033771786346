<form class="mc-spring-board" (ngSubmit)="onSubmit(buildSettingsForm)" [formGroup]="buildSettingsForm">
  <div class="mc-spring-board-body">
    <div class="mc-form-field">
      <mat-checkbox formControlName="Keep" color="primary-vivid">
        Keep Build
      </mat-checkbox>
    </div>

    <p class="mc-form-note">
      You can mark successful builds as "Keep." You might do this to avoid accidentally deleting it, perhaps with the intention of rolling back to it at some point (i.e., setting the site associated with that build to "Live").
    </p>

    <p *ngIf="build?.LinkedToLiveSite" class="mc-form-note">
      You cannot change a build's "Keep" state while it is linked to a "Live" site.
    </p>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="buildSettingsForm.pristine || buildSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>
