<div class="mc-spring-board mc-review-package-overview-spring-board">
  <div class="mc-spring-board-body mc-review-package-overview-spring-board-body">
    <div class="mc-package-overview-chart-container mc-d-flex mc-flex-column">
      <mat-form-field appearance="standard" class="mc-review-package-overview-chart-title" color="primary-vivid">
        <mat-select [(value)]="analyticsView" (selectionChange)="onAnalyticsViewChanged($event)">
          <mat-option value="files">File Progress</mat-option>
          <mat-option *ngIf="reviewPackageUser" value="my">My Progress</mat-option>
          <mat-option value="all">All Users Progress</mat-option>
        </mat-select>
      </mat-form-field>

      <mc-review-file-status-bar-chart *ngIf="analyticsView === 'files'" [analytics]="fileAnalytics$ | async" keyJustification="around"></mc-review-file-status-bar-chart>
      <mc-review-file-reviewer-status-bar-chart *ngIf="analyticsView === 'my' || analyticsView === 'all'" [analytics]="analytics$ | async" keyJustification="around"></mc-review-file-reviewer-status-bar-chart>
    </div>

    <div *ngIf="reviewPackage.Description" class="mc-review-package-overview-description-container">
      <fieldset class="mc-panel mc-panel-large mc-border-divider">
        <legend class="mc-text-normal">Description</legend>

        <p class="mc-review-package-overview-description">
          {{reviewPackage.Description}}
        </p>
      </fieldset>
    </div>

    <mc-review-files-grid *ngIf="reviewPackage" class="mc-review-package-overview-grid" [defaultVisibleColumns]="['Name', 'Status', 'Reviewers']" [gridId]="'review-package-' + reviewPackage.Id" [licenseId]="reviewPackage.LicenseId" [reviewPackageId]="reviewPackage.Id" [reviewersListLimit]="7" [showMenu]="false"></mc-review-files-grid>
  </div>
</div>