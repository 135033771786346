<form novalidate class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div *ngIf="!readOnly" class="mc-spring-board-header">
    <mc-selection-list-header *ngIf="licenseTeams?.length > 0" [avatarSpacing]="true" (selectAllChange)="teamSelectionUpdated()" [selectionListItems]="licenseTeams"></mc-selection-list-header>
  </div>

  <div class="mc-spring-board-body">
    <ng-container *ngIf="readOnly">
      <mat-list *ngIf="site.SiteTeams.length > 0; else noReadTeams" #teamsList>
        <mat-list-item *ngFor="let teamItem of site.SiteTeams">
          <mc-team-avatar [team]="teamItem.TeamId | mcTeamAsync | async" [size]="'small'" [truncate]="true"></mc-team-avatar>
        </mat-list-item>
      </mat-list>
      <ng-template #noReadTeams>
        <mc-no-item-placeholder class="text-light-weight" [showIcon]="false" size="normal" message= "Assign teams to this site in order to see them here."></mc-no-item-placeholder>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!readOnly">
      <ng-container *ngIf="licenseTeams">
        <mat-selection-list *ngIf="licenseTeams.length > 0 else noSelectionTeams" class="mat-primary-vivid" #teamsList>
          <mat-list-option *ngFor="let teamItem of licenseTeams" checkboxPosition="before" [value]="teamItem.Value" [selected]="teamItem.Selected" (click)="toggleTeamSelection(teamItem)">
            <mc-team-avatar [team]="teamItem.TeamId | mcTeamAsync | async" [size]="'small'" [truncate]="true" [disabled]="true"></mc-team-avatar>
          </mat-list-option>
        </mat-selection-list>
        <ng-template #noSelectionTeams>
          <mc-no-item-placeholder class="text-light-weight" [showIcon]="false" size="normal" message= "Please create teams first in order to select them here."></mc-no-item-placeholder>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="mc-spring-board-form-footer">
    <ng-container *ngIf="readOnly">
      <button mat-raised-button type="button" color = "primary" (click)="onEditTeamsClicked()">Edit</button>
    </ng-container>

    <ng-container *ngIf="!readOnly">
      <mc-errors-expansion-panel *ngIf="savingSitesState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingSitesState.detailedErrors$ | async"></mc-errors-expansion-panel>
      <div class="mc-form-buttons">
        <button mat-stroked-button type = "button" (click)="onCancelClicked()" >Cancel</button>
        <button mat-raised-button type = "submit" color="primary"  [disabled] = "teamPristine" >Save</button>
      </div>
    </ng-container>
  </div>

  <mc-loader *ngIf="savingSitesState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
</form>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>
