<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-body">
    <p class="mc-form-text">Delete <b>{{taskBoard?.Title}}?</b></p>
    <p class="mc-form-text">Deleting a task board cannot be undone. Messages and references to the task board will be retained in the Tasks Activity.</p>
    <p class="mc-form-title">Type "<b>{{taskBoard?.Title}}</b>" in the text box below to delete the task board.</p>

    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input type="text" matInput name="confirm" #confirmInput placeholder="Confirm Delete">
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="warn" [disabled]="confirmInput.value !== taskBoard?.Title">Delete</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>