<mc-grid-header-filter [column]="column" [hasFilter]="hasFilter" (menuClosed)="onMenuClosed()" (menuClosing)="onMenuClosing()" (menuOpened)="onMenuOpened()">
  <ng-template mcGridHeaderFilterForm>
    <form #filterFormDirective="ngForm" class="mc-grid-select-filter-form" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <!-- Wait to render the autocomplete component until the menu is open so that its list is the correct size. This ensures the correct number of items are requested from the server -->
      <mat-form-field *ngIf="column.autocomplete && menuOpen" color="primary-vivid">
        <mat-label>Search</mat-label>
        <mc-autocomplete-input formControlName="options" [popupHeight]="220" [ngSwitch]="column.autocomplete">
          <mc-projects-select-list *ngIf="column.autocomplete === 'projects'" mcAutocompleteList [licenseId]="columnInputs.licenseId" type="license"></mc-projects-select-list>
          <mc-projects-select-list *ngIf="column.autocomplete === 'review-projects'" mcAutocompleteList [licenseId]="columnInputs.licenseId" type="review"></mc-projects-select-list>

          <ng-template *ngIf="column.autocomplete === 'projects' || column.autocomplete === 'review-projects'" mcAutocompleteItem let-projectId="item">
            <mc-project-avatar compact [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
          </ng-template>
        </mc-autocomplete-input>
      </mat-form-field>

      <mat-selection-list *ngIf="!column.autocomplete" class="mc-grid-select-filter-mat-selection-list mc-selection-list-multi-line mat-primary-vivid" formControlName="options">
        <mat-list-option *ngFor="let item of column.selectOptions" [value]="item.id">{{item.text}}</mat-list-option>
      </mat-selection-list>

      <div class="mc-menu-button-group">
        <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
        <button type="submit" mat-flat-button color="primary">Filter</button>
      </div>
    </form>
  </ng-template>

  <ng-template *ngIf="menuTemplate" mcGridHeaderFilterMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-header-filter>
