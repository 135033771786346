import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TrustedDomainGroup } from '@portal-core/sites/models/trusted-domain-group.model';
import { SitesApiService } from '@portal-core/sites/services/sites-api.service';
import { TrustedDomainGroupDataService } from '@portal-core/sites/services/trusted-domain-group/trusted-domain-group-data.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrustedDomainGroupService extends CollectionServiceBase<TrustedDomainGroup> {
  constructor(private trustedDomainGroupDataService: TrustedDomainGroupDataService, private sitesApiService: SitesApiService, protected dataService: DataService) {
    super(trustedDomainGroupDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<TrustedDomainGroup> {
    return this.sitesApiService.getTrustedDomainGroupById$(itemId);
  }

  getTrustedDomainGroupsByLicenseId$(licenseId: number, options?: GetDataOptions): Observable<TrustedDomainGroup[]> {
    return this.dataService.getDataListItems$<TrustedDomainGroup>('Licenses', licenseId, this.trustedDomainGroupDataService, {
      fetch: () => this.sitesApiService.getTrustedDomainGroupsByLicenseId$(licenseId).pipe(
        map(trustedDomainGroups => {
          if (Array.isArray(trustedDomainGroups)) {
            return trustedDomainGroups.sort((trustedDomainGroupA, trustedDomainGroupB) => trustedDomainGroupA.Name.localeCompare(trustedDomainGroupB.Name));
          }
        })
      )
    }, options);
  }

  createTrustedDomainGroup$(licenseId: number, trustedDomainGroupName: string): Observable<TrustedDomainGroup> {
    return this.sitesApiService.upsertTrustedDomainGroup$(licenseId, trustedDomainGroupName).pipe(
      tap(trustedDomainGroup => {
        this.trustedDomainGroupDataService.addItems$([trustedDomainGroup]);
        this.trustedDomainGroupDataService.addItemsToListById$('Licenses', licenseId, [trustedDomainGroup]);
      })
    );
  }

  upsertTrustedDomains$(licenseId: number, updatedTrustedDomainGroup: TrustedDomainGroup): Observable<MadCloudResult> {
    return this.sitesApiService.upsertTrustedDomains$(licenseId, updatedTrustedDomainGroup).pipe(
      tap(() => {
        this.trustedDomainGroupDataService.updateItems$([updatedTrustedDomainGroup]);
      })
    );
  }

  getTrustedDomainGroupsPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<TrustedDomainGroup>> {
    return this.sitesApiService.getTrustedDomainGroupsPageByLicenseId$(licenseId, filter);
  }

  removeTrustedDomainGroup$(trustedDomainGroupId: number): Observable<MadCloudResult> {
    return this.sitesApiService.removeTrustedDomainGroup$(trustedDomainGroupId).pipe(
      tap(() => {
        this.trustedDomainGroupDataService.removeItems$([trustedDomainGroupId]);
      })
    );
  }
}
