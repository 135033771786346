<div #trigger class="mc-project-file-picker-input-trigger" mcPopupClass="mc-project-file-picker-input-popup" [mcPopupHeight]="popupHeight" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex">
  <ng-container *ngIf="!empty || !placeholder; else emptyPlaceholder">
    <mat-chip-list class="mc-project-file-picker-input-chip-list">
      <mat-chip *ngIf="value" class="mc-project-file-picker-input-chip" [removable]="true" [selectable]="false" (mcClick)="onFileChipRemoved()" (removed)="onFileChipRemoved()">
        <ng-container *ngIf="itemDirective; else defaultValue" [ngTemplateOutlet]="itemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: isPathWithLeadingSlash ? (value | mcRemoveLeadingSlash) : value }"></ng-container>
        <ng-template #defaultValue>
          <mc-self-truncating-path class="mc-project-file-picker-input-value" [filePath]="isPathWithLeadingSlash ? (value | mcRemoveLeadingSlash) : value"></mc-self-truncating-path>
        </ng-template>
        <mat-icon matChipRemove fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <ng-template #emptyPlaceholder>
    <input matInput [placeholder]="placeholder" readonly="true" type="text">
  </ng-template>
  <mc-dropdown-arrow class="mc-project-file-picker-input-arrow"></mc-dropdown-arrow>
</div>

<mc-popup #popup (afterOpen)="onPopupOpened()">
  <mc-project-files-tree #filesTree [treeData]="{projectId, branchName}" [fileFilter]="fileFilter" [show404ErrorAsEmptyTree]="true" [rootFolder]="rootFolder" [treeClass]="treeClass" (fileSelected)="onFileSelected($event)">
    <ng-template *ngFor="let treeItemDirective of treeItemDirectives" [mcProjectFilesTreeItem]="treeItemDirective.templateName" let-item>
      <ng-container [ngTemplateOutlet]="treeItemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </ng-template>
  </mc-project-files-tree>
</mc-popup>