<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <div class="mc-spring-board-header">
      <mc-project-avatar [project]="project$ | async" [disabled]="true" [showName]="false"></mc-project-avatar>
    </div>
    <div class="mc-spring-board-body">
      <ng-container *ngIf="project$ | async as project">
        <h3 class="text-x-large">{{project?.Name}}</h3>
        <p class="text-light-weight">{{project?.Description}}</p>
      </ng-container>
    </div>

    <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
      <a href="#" *ngIf="showOverviewTab$ | async" mat-list-item [mcProfileTab]="ProjectProfileTab.Overview" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-overview'" color="primary"></mat-icon>Overview
      </a>
      <a href="#" *ngIf="showSettingsTab$ | async" mat-list-item [mcProfileTab]="ProjectProfileTab.Settings" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-gear'" color="primary"></mat-icon>Settings
      </a>
      <a href="#" *ngIf="showAccessTab$ | async" mat-list-item [mcProfileTab]="ProjectProfileTab.Access" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-assign-task'" color="primary"></mat-icon> Access
      </a>
      <a href="#" *ngIf="showActivityTab$ | async" mat-list-item [mcProfileTab]="ProjectProfileTab.Activity" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-clock'" color="primary"></mat-icon> Activity
      </a>
      <a href="#" *ngIf="showDeleteTab$ | async" mat-list-item [mcProfileTab]="ProjectProfileTab.Delete" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'" color="primary"></mat-icon> Delete
      </a>

      <mat-divider *ngIf="(showOverviewTab$ | async) || (showSettingsTab$ | async) || (showAccessTab$ | async) || (showActivityTab$ | async) || (showDeleteTab$ | async)" class="mc-project-profile-switch-board-bar-nav-list-divider"></mat-divider>

      <ng-container *ngIf="project$ | async as project">
        <a *ngIf="project | mcProjectLink : 'dashboard' as dashboardLink" mat-list-item [routerLink]="dashboardLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-projectdashicon" color="primary"></mat-icon> Dashboard
        </a>
        <a *ngIf="project | mcProjectLink : 'builds' as buildsLink" mat-list-item [routerLink]="buildsLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-build" color="primary"></mat-icon> Builds
        </a>
        <a *ngIf="project | mcProjectLink : 'checklists' as checklistsLink" mat-list-item [routerLink]="checklistsLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-check" color="primary"></mat-icon> Checklists
        </a>
        <a *ngIf="project | mcProjectLink : 'files' as filesLink" mat-list-item [routerLink]="filesLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-files-o" color="primary"></mat-icon> Files
        </a>
        <a *ngIf="project | mcProjectLink : 'commits' as commitsLink" mat-list-item [routerLink]="commitsLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-commit" color="primary"></mat-icon> Commits
        </a>
        <a *ngIf="project | mcProjectLink : 'reports' as reportsLink" mat-list-item [routerLink]="reportsLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-bar-chart" color="primary"></mat-icon> Reports
        </a>
      </ng-container>
    </mat-nav-list>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="profileTab" class="mc-spring-board">
    <mat-tab-group *ngSwitchCase="ProjectProfileTab.Overview" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Overview">
        <mc-project-overview [projectInfo]="projectInfo$ | async"></mc-project-overview>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="ProjectProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-project-settings-form [project]="project$ | async" (saved)="onSettingsSaved()" (cancel)="onCancel()"></mc-project-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="ProjectProfileTab.Access" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Access">
        <mc-project-access-form class="mc-switch-board-body-slim" [project]="project$ | async" (saved)="onProjectAccessSaved()"></mc-project-access-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="ProjectProfileTab.Activity" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Activity">
        <mc-project-notification-list [projectId]="(project$ | async).Id"></mc-project-notification-list>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="ProjectProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete">
        <mc-project-deletion-form [project]="project$ | async" (deleted)="onProjectDeleted()" (cancel)="onCancel()"></mc-project-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>