import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class BranchesFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'BranchName',
        title: 'Branch',
        filterEnabled: true,
        sortEnabled: true,
        type: PageDataType.String
      },
      {
        name: 'Name',
        title: 'Branch',
        filterEnabled: true,
        sortEnabled: true,
        type: PageDataType.String
      }
    ]);
  }
}
