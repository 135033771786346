import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SelfTruncatingPathModule } from '@portal-core/general/components/self-truncating-path/self-truncating-path.module';
import { ProjectFilesTreeModule } from '@portal-core/project-files/components/project-files-tree/project-files-tree.module';
import { ProjectFilePickerInputItemModule } from '@portal-core/project-files/directives/project-file-picker-input-item/project-file-picker-input-item.module';
import { ProjectFilesTreeItemModule } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.module';
import { RemoveLeadingSlashModule } from '@portal-core/project-files/pipes/remove-leading-slash/remove-leading-slash.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { ClickModule } from '@portal-core/ui/clicking/directives/click/click.module';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { ProjectFilePickerInputComponent } from './project-file-picker-input.component';

@NgModule({
  declarations: [
    ProjectFilePickerInputComponent
  ],
  imports: [
    A11yModule,
    ClickModule,
    CommonModule,
    DropdownArrowModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    PopupModule,
    ProjectFilesTreeModule,
    ProjectFilePickerInputItemModule,
    RemoveLeadingSlashModule,
    SelfTruncatingPathModule,
    TargetAvatarModule
  ],
  exports: [
    ProjectFilePickerInputComponent,
    ProjectFilePickerInputItemModule,
    ProjectFilesTreeItemModule
  ]
})
export class ProjectFilePickerInputModule { }
