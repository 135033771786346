import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StringService } from '@portal-core/general/services/string.service';
import { TreeService } from '@portal-core/general/services/tree.service';
import { ProjectFilesTreeItemDirective } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.directive';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { ProjectFilesChecklistService } from '@portal-core/project-files/services/project-files-checklist.service';
import { ProjectFilesTreeDataService } from '@portal-core/project-files/services/project-files-tree-data.service';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { ProjectFilesTreeType } from '@portal-core/project-files/types/project-files-tree.type';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';

@Component({
  selector: 'mc-project-files-tree-file',
  templateUrl: './project-files-tree-file.component.html',
  styleUrls: ['./project-files-tree-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilesTreeFileComponent {
  @Input() node: ProjectFileFlatNode;
  @Input() routerLink: string[];
  @Input() filePath: string;
  @Input() pathFilters: string[];
  @Input() showIcons?: boolean = true;
  @Input() branchName: string;
  @Input() projectFilesTreeType: ProjectFilesTreeType = 'default';
  @Input() disableSelectionTree: boolean = false;

  @Output() pathFiltersChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() fileSelected: EventEmitter<ProjectFileFlatNode> = new EventEmitter<ProjectFileFlatNode>();

  @ContentChild(ProjectFilesTreeItemDirective) treeItemDirective: ProjectFilesTreeItemDirective;

  TriSelectedState: typeof TriSelectedState = TriSelectedState;

  constructor(private projectFilesService: ProjectFilesService,
    private projectFilesTreeDataService: ProjectFilesTreeDataService,
    private projectFilesChecklistService: ProjectFilesChecklistService,
    private stringService: StringService,
    private treeService: TreeService) { }

  getFileIconName(path: string): string {
    return this.projectFilesService.getFileIconName(path);
  }

  encodeURIComponent(component: string): string {
    return this.stringService.encodeURIComponent(component);
  }

  onNodeCheckboxChanged(flatNode: ProjectFileFlatNode) {
    // don't test TriSelectedState.Indeterminate, it will toggle to Selected
    if (flatNode.selected === TriSelectedState.Selected) {
      flatNode.selected = TriSelectedState.NotSelected;
    } else {
      flatNode.selected = TriSelectedState.Selected;
    }
    const node: ProjectFileNode = this.projectFilesTreeDataService.getNode(flatNode.path);
    this.projectFilesChecklistService.checkParentNodes(node);
    // Set all children
    if (Array.isArray(node.children)) {
      this.treeService.forEach(node, 'children', child => child.flatFileNode.selected = flatNode.selected);
    }
    this.pathFiltersChanged.emit(this.projectFilesChecklistService.buildPathFilters(this.projectFilesTreeDataService.nodeMap, this.pathFilters));
  }
}
