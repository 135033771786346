<form class="mc-spring-board" (ngSubmit)="onSubmit(emailForm)" [formGroup]="emailForm" #formDirective="ngForm">
  <div class="mc-spring-board-body">
    <p class="mc-form-title">
      <b>Current Email:</b> {{ licenseUser?.User?.Email }}
    </p>

    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input matInput type="email" formControlName="newEmail" placeholder="New Email" required [formGroup]="emailForm">
      <mat-error>
        <mc-form-control-error-message [form]="emailForm" controlName="newEmail" type="mc_email"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input matInput type="email" formControlName="confirmEmail" placeholder="Confirm Email" required [formGroup]="emailForm">
      <mat-error>
        <mc-form-control-error-message [form]="emailForm" controlName="confirmEmail" type="mc_confirm_email"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button *ngIf="showCancelButton" type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="emailForm.pristine || emailForm.invalid ||  (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>
