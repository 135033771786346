<form class="mc-spring-board" [formGroup]="licenseSettingsForm" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-body">
    <div class="mc-form-field">
      <mc-image-picker placeholder="Avatar" formControlName="avatar" color="primary-vivid"></mc-image-picker>
      <mat-error class="mc-form-field-subscript">
        <mc-form-control-error-message [form]="licenseSettingsForm" controlName="avatar" type="mc_avatar_image"></mc-form-control-error-message>
      </mat-error>
    </div>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Name</mat-label>
      <input matInput type="text" placeholder="Name" formControlName="keyLabel" required>
      <mat-error>
        <mc-form-control-error-message [form]="licenseSettingsForm" controlName="keyLabel" type="mc_license_key_label"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Vanity</mat-label>
      <input matInput type="text" placeholder="Vanity" formControlName="vanityBaseUrl" required>
      <mat-error>
        <mc-form-control-error-message [form]="licenseSettingsForm" controlName="vanityBaseUrl" type="mc_vanity"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <p class="mc-form-note">
      The vanity will be used to access both Central via login, as well as outputs that you host on Central. Changing the vanity will immediately log out any users logged into this license{{ redirectOnVanityChange ? ' and you will be redirected to the new Central URL' : '' }}.
    </p>
    <p class="mc-form-note">
      For more information, <a target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=URL_Vanity_Name">click here.</a>
    </p>

    <p class="mc-form-label">Central URL</p>
    <p class="mc-form-value mc-break-text">{{ licenseSettingsForm.get('vanityBaseUrl').value }}.{{ centralPortalDomain }}</p>
    <p class="mc-form-label">Output URL</p>
    <p class="mc-form-value mc-break-text">{{ outputDomain$ | async }}</p>

    <ng-container *ngIf="showAdminFields">
      <mat-form-field appearance="standard" color="primary-vivid" class="mc-form-field">
        <mat-label>Author Seats</mat-label>
        <input formControlName="maxAuthorSeats" matInput type="number" placeholder="Author Seats" required>
        <mat-error>
          <mc-form-control-error-message [form]="licenseSettingsForm" controlName="maxAuthorSeats" type="mc_license_seats"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" color="primary-vivid" class="mc-form-field">
        <mat-label>SME Seats</mat-label>
        <input formControlName="maxSmeSeats" matInput type="number" placeholder="SME Seats" required>
        <mat-error>
          <mc-form-control-error-message [form]="licenseSettingsForm" controlName="maxSmeSeats" type="mc_license_seats"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <div class="mc-form-fields-row">
        <mat-form-field appearance="standard" color="primary-vivid">
          <mat-label>Storage Space</mat-label>
          <input formControlName="maxStorage" matInput type="number" placeholder="Storage Space {{licenseSettingsForm.get('maxStorage').value * licenseSettingsForm.get('bytes').value | mcFileSize}}" min="0" required>
          <mat-error>
            <mc-form-control-error-message [form]="licenseSettingsForm" controlName="maxStorage" type="mc_license_storage_space"></mc-form-control-error-message>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard" color="primary-vivid">
          <mat-label>Measurement Unit</mat-label>
          <mat-select formControlName="bytes" placeholder="Measurement Unit">
            <mat-option [value]="SizeBytes.Megabyte">Megabytes</mat-option>
            <mat-option [value]="SizeBytes.Gigabyte" selected>Gigabytes</mat-option>
            <mat-option [value]="SizeBytes.Terabyte">Terabytes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container> <!-- showAdminFields -->
  </div><!-- .mc-spring-board-body -->

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>

  <ng-container *ngIf="savingState.loading$ | async">
    <mc-loader *ngIf="!redirectOnVanityChange || !changingVanity" class="mc-loader-semi-transparent"></mc-loader>

    <div *ngIf="redirectOnVanityChange && changingVanity" class="mc-loader mc-license-settings-form-redirect-loader">
      <mat-progress-spinner [diameter]="100" color="primary" mode="indeterminate"></mat-progress-spinner>
      <p class="mc-form-text">After the settings have been saved you will be redirected to the new Central URL.</p>
    </div>
  </ng-container>

  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>
</form>