
<form novalidate class="mc-spring-board" [formGroup]="changeSecuritySettingsForm" (ngSubmit)="onSaveSecuritySettings(changeSecuritySettingsForm)">
  <div class="mc-spring-board-body">
    <p class="mc-form-heading">MadCap<sup class="mc-text-super">&reg;</sup> ID Settings</p>

    <div class="mc-license-security-form-checkbox-container">
      <mat-checkbox matInput type="checkbox" color="primary-vivid" formControlName="maxFailedAccessAttemptsEnabled" [disabled]="readOnly">Login attempts allowed:</mat-checkbox>
    </div>
    <mat-form-field color="primary-vivid" class="mc-license-security-form-field">
      <mat-select class="mc-select-align-right" formControlName="maxFailedAccessAttemptsValue">
        <mat-option *ngFor="let item of maxFailedAccessAttemptsOptions" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
      <span matSuffix>&nbsp;attempts</span>
    </mat-form-field>

    <div class="mc-license-security-form-checkbox-container">
      <mat-checkbox matInput type="checkbox" color="primary-vivid" formControlName="requirePasswordChangeEnabled" [disabled]="readOnly">Require password change after:</mat-checkbox>
    </div>
    <mat-form-field color="primary-vivid" class="mc-license-security-form-field">
      <mat-select class="mc-select-align-right" formControlName="requirePasswordChangeValue">
        <mat-option *ngFor="let item of requiredPasswordChangeDaysOptions" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
      <span matSuffix>&nbsp;days</span>
    </mat-form-field>
    <div class="mc-license-security-form-checkbox-container">
      <mat-checkbox matInput type="checkbox" color="primary-vivid" formControlName="passwordMinimumLengthEnabled" [disabled]="readOnly">Password minimum:</mat-checkbox>
    </div>

    <mat-form-field color="primary-vivid" class="mc-license-security-form-field">
      <mat-select class="mc-select-align-right" formControlName="passwordMinimumLengthValue">
        <mat-option *ngFor="let item of passwordMinimumCharacters" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
      <span matSuffix>&nbsp;characters</span>
    </mat-form-field>

    <p class="mc-form-heading">MadCap<sup class="mc-text-super">&reg;</sup> ID and Single Sign-On Settings</p>

    <div class="mc-license-security-form-checkbox-container">
      <mat-checkbox matInput type="checkbox" color="primary-vivid" formControlName="maxIdleTimeEnabled" [disabled]="readOnly">Logout after idle for:</mat-checkbox>
    </div>
    <mat-form-field color="primary-vivid" class="mc-license-security-form-field">
      <mat-select class="mc-select-align-right" formControlName="maxIdleTimeValue">
        <mat-option *ngFor="let item of maxIdleTimeMinutesOptions" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
      <span matSuffix>&nbsp;minutes</span>
    </mat-form-field>

    <mc-loader *ngIf="savingSettings" class="mc-loader-semi-transparent"></mc-loader>
  </div>

  <div class="mc-spring-board-footer">
    <button type="button" mat-stroked-button class="mc-dialog-card-action-button" (click)="closeDialog.emit(true)">Cancel</button>
    <button type="submit" mat-raised-button class="mc-dialog-card-action-button" color="primary" [disabled]="readOnly || changeSecuritySettingsForm.pristine || changeSecuritySettingsForm.invalid || savingSettings">Save</button>
  </div>
</form>
