<form class="mc-spring-board" novalidate
  (ngSubmit)="saveChannelSubscription(editSlackNotificationForm.value, formGroupDirective)" [formGroup]="editSlackNotificationForm" #formGroupDirective="ngForm">
  <mc-loader *ngIf="awaitingResponse" class="mc-loader-semi-transparent"></mc-loader>
  <div class="mc-spring-board-body">
    <!-- Manage View -->
    <mat-accordion displayMode="flat" multi *ngIf="!showEdit">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-large">
            Channels
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button mat-stroked-button type="button" color="link" class="mc-button-normal mc-slack-add-button" (click)="addSlackChannel()">Connect a Channel</button>
        <mat-list role="list">
          <mat-list-item role="listitem" class="mc-slack-expansion-list-item" *ngFor="let channel of channels">
            <span>{{channel.Name}}</span>
            <span class="mc-fill-remaining-flex-space"></span>
            <button type="button" mat-icon-button color="link" (click)="deleteSlackChannel(channel)">
              <mat-icon class="quick-link-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" [disabled]="channels == null || channels.length == 0">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-large">
            Notifications
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button mat-stroked-button type="button" color="link" class="mc-button-normal mc-slack-add-button" (click)="editSlackChannelSubscription()">Add Notification</button>
        <mat-list role="list">
          <mat-list-item role="listitem" class="mc-slack-expansion-list-item" *ngFor="let subscriber of subscribers">
            <span>{{subscriber.Name}}</span>
            <span class="mc-fill-remaining-flex-space"></span>
            <button type="button" mat-icon-button color="link" (click)="editSlackChannelSubscription(subscriber)">
              <mat-icon class="quick-link-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-pen'"></mat-icon>
            </button>
            <button type="button" mat-icon-button color="link" (click)="deleteSlackSubscriber(subscriber)">
              <mat-icon class="quick-link-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Edit View -->
    <div class="mc-edit-notification-view" *ngIf="showEdit">
      <h4 class="text-large mc-form-text">Notifications</h4>
      <div class="mc-notification-options">
        <mat-form-field color="primary-vivid">
          <input matInput formControlName="name" placeholder="Name">
        </mat-form-field>
        <mat-form-field color="primary-vivid">
          <mat-select formControlName="channel" placeholder="Channel">
            <mat-option *ngFor="let channel of channels" [value]="channel">
              {{channel.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary-vivid">
          <mat-select #activitySelect formControlName="activityType" placeholder="Activity Type">
            <mat-option *ngFor="let activity of activityTypes" [value]="activity">
              {{activity.Title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary-vivid" [class.mc-hidden]="editSlackNotificationForm?.value?.activityType?.Title !== 'Tasks'">
          <mat-select #taskSelect formControlName="task" placeholder="Task">
            <mat-option value="all">All Tasks</mat-option>
            <mat-option value="allWithoutProject">All Tasks WITHOUT a Project</mat-option>
            <mat-option value="allWithProject">All Tasks for a Specific Project</mat-option>
            <mat-option *ngFor="let task of tasks$ | async" [value]="task.Id">
              {{task?.Title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          color="primary-vivid"
          [class.mc-hidden]="!(editSlackNotificationForm?.value?.activityType?.Title == 'Builds' ||
                editSlackNotificationForm?.value?.activityType?.Title == 'Checklists' ||
                editSlackNotificationForm?.value?.activityType?.Title == 'Projects' ||
                (editSlackNotificationForm?.value?.activityType?.Title == 'Tasks' && taskSelect?.value === 'allWithProject'))">
          <mat-select #projectSelect formControlName="project" placeholder="Project"
            (selectionChange)="updateTargets(projectSelect?.value)">
            <mat-option *ngIf="editSlackNotificationForm?.value?.activityType?.Title !== 'Tasks'" value="all">All Projects</mat-option>
            <mat-option *ngFor="let project of projects$ | async" [value]="project.Id">
              {{project?.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          color="primary-vivid"
          [class.mc-hidden]="!(editSlackNotificationForm?.value?.activityType?.Title == 'Builds' &&
            projectSelect?.value !== 'all' &&
            projectSelect?.value !== '')">
          <mat-select #targetSelect formControlName="target" placeholder="Target">
            <mat-option value="all">All Targets</mat-option>
            <mat-option *ngFor="let target of targets$ | async" [value]="target.Id">
              {{target?.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary-vivid" [class.mc-hidden]="editSlackNotificationForm?.value?.activityType?.Title !== 'Teams'">
          <mat-select #teamSelect formControlName="team" placeholder="Team">
            <mat-option value="all">All Teams</mat-option>
            <mat-option *ngFor="let team of teams$ | async" [value]="team.Id">
              {{team?.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary-vivid" [class.mc-hidden]="editSlackNotificationForm?.value?.activityType?.Title !== 'Sites'">
          <mat-select #siteSelect formControlName="site" placeholder="Site">
            <mat-option value="all">All Sites</mat-option>
            <mat-option *ngFor="let site of sites$ | async" [value]="site.Id">
              {{site?.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary-vivid" [class.mc-hidden]="editSlackNotificationForm?.value?.activityType?.Title !== 'Users'">
          <mat-select #userSelect formControlName="user" placeholder="User">
            <mat-option value="all">All Users</mat-option>
            <mat-option *ngFor="let licenseUser of users$ | async" [value]="licenseUser.User.Id">
              {{licenseUser?.User?.FullName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <h4 class="mc-slack-activity-header">Activity:</h4>
        <mat-selection-list class="mat-primary-vivid">
          <ng-container *ngFor="let activity of activityTypes">
            <mat-list-option class="text-light-weight" *ngFor="let auditCategory of activity.SubCategories"
              [class.mc-hidden]="activity.AuditTargetPageType != editSlackNotificationForm?.value?.activityType?.AuditTargetPageType"
              checkboxPosition="before"
              color="primary-vivid"
              [selected]="auditCategorySelection.isSelected(auditCategory)"
              (click)="toggleCategory(auditCategory)">
              {{auditCategory.Title}}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
    </div>
  </div>
  <div class="mc-spring-board-footer">
    <button type="button" *ngIf="!showEdit" mat-stroked-button (click)="closeDialog.emit(true)">Cancel</button>
    <ng-container *ngIf="showEdit">
      <button type="reset" mat-stroked-button
        (click)="resetForm(formGroupDirective)">Cancel</button>
      <button type="submit" mat-raised-button color="primary"
        [disabled]="editSlackNotificationForm.pristine || editSlackNotificationForm.invalid">Add</button>
    </ng-container>
  </div>
</form>
