import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridFilterSelectOption } from '@portal-core/ui/grid/models/grid-filter-select-option.model';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

function getProjectStatusSelectOptions(): GridFilterSelectOption[] {
  const options: GridFilterSelectOption[] = [];

  for (const name in ProjectStatus) {
    if (typeof ProjectStatus[name] === 'number') {
      options.push({ id: ProjectStatus[name].toString(), text: name });
    }
  }

  return options;
}

export class ProjectPageFilterConfig extends PageFilterConfigBase {
  static readonly Columns: GridColumn[] = [
    {
      name: 'Name',
      title: 'Name',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'Initials',
      title: 'Initials',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'ProjectTeams',
      title: 'Teams',
      type: PageDataType.String
    },
    {
      name: 'ProjectUsers',
      title: 'Users',
      type: PageDataType.String
    },
    {
      name: 'Status',
      title: 'Status',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      selectOptions: getProjectStatusSelectOptions()
    },
    {
      name: 'LastBuild.BuiltOn',
      title: 'Last Built',
      type: PageDataType.Date
    },
    {
      name: 'Description',
      title: 'Description',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'LastActivity',
      title: 'Last Activity',
      type: PageDataType.Date
    },
    {
      name: 'TotalSourceFileSize',
      title: 'Source Size',
      type: PageDataType.Int
    },
    {
      name: 'TotalProjectStorage',
      title: 'Total Size',
      type: PageDataType.Int
    },
    {
      name: 'CreatedOn',
      title: 'Date Uploaded',
      type: PageDataType.Date,
      filterEnabled: true,
      sortEnabled: true,
    }
  ];

  constructor(enabledColumnNames?: string[]) {
    super(enabledColumnNames, ProjectPageFilterConfig.Columns);
  }
}
