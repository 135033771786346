<div class="mat-tree-node">
  <div matRipple class="mc-tree-node-item mc-project-files-tree-folder-container" [style]="'--mc-flat-tree-node-level:' + this.node.level" (click)="onFolderClicked(node)">
    <!-- rail -->
    <div *ngFor="let i of (node.level | mcRange); let level = index" class="mc-tree-node-rail mc-border-divider" [style]="'--mc-flat-tree-rail-level:' + level"></div>

    <!-- selection or normal-->
    <div *ngIf="projectFilesTreeType !== 'selection'">
      <ng-template [ngTemplateOutlet]="nodeTemplate"></ng-template>
    </div>

    <mat-checkbox *ngIf="projectFilesTreeType === 'selection'" [checked]="node.selected === TriSelectedState.Selected" [disabled]="disableSelectionTree" class="mc-tree-text-icon" color="primary-vivid" disableRipple="true" [indeterminate]="node.selected === TriSelectedState.Indeterminate" mcStopPropagation="click" (change)="onNodeCheckboxChanged(node)">
      <ng-template [ngTemplateOutlet]="nodeTemplate"></ng-template>
    </mat-checkbox>

    <!--Error and loading -->
    <mat-progress-bar mode="indeterminate" *ngIf="node.isLoading === true" color="task-low-priority"> </mat-progress-bar>
    <div class="mc-project-files-tree-folder-error" *ngIf="node.error === true">
      <mat-icon class="image-icon image-icon-xxx-small mc-error-message mc-tree-text-icon" fontSet="project-titan" fontIcon="icon-alert" matTooltip="Unable to load the project files. Click to try again."></mat-icon>
    </div>
  </div>
</div>

<ng-template #nodeTemplate>
  <ng-container *ngIf="treeItemDirective; else defaultNode" [ngTemplateOutlet]="treeItemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: node, expanded: isFolderExpanded }"></ng-container>
  <ng-template #defaultNode>
    <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="isFolderExpanded ? 'icon-down-arrow-90' : 'icon-right-arrow-90'" mcPreventDefault="click" mcStopPropagation="click" (click)="onFolderClicked(node)"></mat-icon>
    {{node.name}}
  </ng-template>
</ng-template>