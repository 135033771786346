const TopicFileExts = 'htm|html';
const StylesheetFileExt = 'css';
const SnippetFileExts = 'flmsp|flsnp';
const ImageFileExts = 'bmp|gif|jpeg|jpg|png|tif|tiff';
const FlareFileExts = `${TopicFileExts}|${SnippetFileExts}|${StylesheetFileExt}|flpgl|flmco`;
const ProjectFileExts = 'flbrs|flsfs|flixl|flaix|fllng|flali|h|flrtb|flfts|fltbx|mcsyns|mcdic|mcign|mchyph|fldes|flexp|flglo|flimpmd|flimp|flimpxls|flimpfm|flimpdita|flimphtml|flimpfl|flrep|mclog|flskn|fltar|flbat|fltoc|flvar|flcts|flmeta';

export const AllFileFilter = '.*';
export const FlareFileFilter = `\.(${TopicFileExts}|${SnippetFileExts})$`;
export const ImagesFilter = `\.(${ImageFileExts})$`;
export const AllImagesFilter = `\.(${ImageFileExts}|swd|emf|wmf|xaml|xps|exps|hdp|wdp|svg|ps|eps|dib)$`;
export const TopicFileFilter = `\.(${TopicFileExts})$`
export const MultimediaFilter = '\.(asf|wm|wma|wmv|asx|wax|wvx|wmx|mid|midi|mpeg|mpg|mpa|mpe|wav|au|mp3|avi|swf|mov|qt|mp4|webm|ogg|ogv|oga|ogx|spx|opus|m4v|u3d)$';
export const HTML5VideoFilter = '\.(webm|ogg|ogv|oga|ogx|spx|opus|avi|mp4|m4v)$';
export const SnippetFileFilter = `\.(${SnippetFileExts})$`;
export const TocFileFilter = '\.fltoc$';
export const TargetFileFilter = '\.(fltar|flbat)$';
export const VariableSetFileFilter = '\.(mcvar|flvar)$';
export const ConditionalTagSetFileFilter = '\.flcts$';
export const SkinFileFilter = '\.flskn$';
export const TemplateFileFilter = '\.flmsp$';
export const PageLayoutFileFilter = '\.flpgl$';
export const StylesheetsFileFilter = `\.(${StylesheetFileExt})$`
export const ProjectFileFilter = `\.(${ProjectFileExts})$`;
export const EditableFileFilter = `\.(${FlareFileExts}|${ProjectFileExts})$`;
