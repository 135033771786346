import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { InfiniteListComponent } from '@portal-core/ui/list/components/infinite-list/infinite-list.component';
import { ListOptionComponent } from '@portal-core/ui/list/components/list-option/list-option.component';
import { AllItemsLoadedListDirective } from '@portal-core/ui/list/directives/all-items-loaded-list/all-items-loaded-list.directive';
import { EmptyListDirective } from '@portal-core/ui/list/directives/empty-list/empty-list.directive';
import { ListItemDirective } from '@portal-core/ui/list/directives/list-item/list-item.directive';
import { ListType } from '@portal-core/ui/list/enums/list-type.enum';
import { EmptyListData } from '@portal-core/ui/list/models/empty-list-data.model';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { reduce } from 'lodash';
import { Observable, Subscription, combineLatest, filter, map, of, switchMap } from 'rxjs';

/**
 * DataListBase
 * Connects a collection data store to an infinite list.
 *
 * Resize Behavior:
 * When the browser is resized or checkViewportSize is called the list will make a check to see if more items need to be loaded to fill the viewport.
 * The check is simply whether the new page size is greater than the old page size. If that is the case then the list is reloaded with the new page size.
 * When the user scrolls to the end of the list more items are loaded based on the page size.
 * If the user were to resize the viewport to be smaller and more items were fetched with the smaller page size then there would be a mismatch of pages sizes in the list's data.
 * This would cause missing or repeated items in the list. To prevent this the larger page size is kept when the viewport is decreased.
 * This means that the largest page size is always used during the lifetime of the list.
 */
@Directive()
@AutoUnsubscribe()
export abstract class DataListBase implements AfterViewInit, OnDestroy {
  /** The unique id for this data list. */
  @Input() dataListId: string;
  /** The height of every item in the list. Defaults to the standard list item height (48) */
  @Input() itemHeight?: number = 48; // The default height for list items
  /** Class to be added to the Material list element */
  @Input() listClass?: string;
  /** The list control is used to fetch and store the items in the data list. */
  @Input() listControl: ListControl;
  /** The background color of the loader. */
  @Input() loaderBackgroundColor: 'body' | 'component';
  /** The foreground color of the loader. */
  @Input() loaderColor?: ThemePalette;

  @ViewChild(InfiniteListComponent) infiniteList: InfiniteListComponent;
  @ViewChild(InfiniteListComponent, { read: ElementRef }) listElementRef: ElementRef;
  @ContentChild(AllItemsLoadedListDirective) allItemsLoadedListDirective: AllItemsLoadedListDirective;
  @ContentChild(EmptyListDirective) emptyListDirective: EmptyListDirective;
  @ContentChild(ListItemDirective) listItemDirective: ListItemDirective;

  @PropertyObservable('dataListId') dataListId$: Observable<string>;

  /** A reference to the underlying key manager. Only defined for Select lists. */
  get keyManager(): ActiveDescendantKeyManager<ListOptionComponent> {
    return this.infiniteList?.keyManager;
  }

  ListType: typeof ListType = ListType;

  allPagesLoaded$: Observable<boolean>;
  emptyListData$: Observable<EmptyListData>;
  endScrollSubscription: Subscription;
  items$: Observable<any[]>;
  loadingState: LoadingState<string> = new LoadingState<string>();
  loadingMoreState: LoadingState<string> = new LoadingState<string>();
  loadMoreSubscription: Subscription;
  // SubscriptionProperty is used to ensure that any pending loads are cancelled when a new load is initiated
  @SubscriptionProperty() loadSubscription: Subscription;
  reloadSubscription: Subscription;
  viewportChangeSubscription: Subscription;

  // Stores the pageSize for page size calculations in getListViewportPageSize
  private pageSize: number;

  /**
   * constructor
   * @param errorService Used to get error messages when data fails to load.
   * @param viewportRuler Used to listen to browser viewport size change events.
   */
  constructor(protected errorService: ErrorService, protected viewportRuler: ViewportRuler) { }

  /**
   * Removes the grid's data.
   */
  ngOnDestroy() {
    // Clean up the data. It is no longer needed.
    if (this.dataListId && this.listControl) {
      this.listControl.collectionService.clearPagedDataList$(this.dataListId);
    }
  }

  /**
   * ngAfterViewInit
   * The observables are setup in ngAfterViewInit instead of ngOnInit because they are dependent on the list element being rendered in the view (to calculate the page size.)
   */
  ngAfterViewInit() {
    // Make an observable of the items to render
    this.items$ = this.dataListId$.pipe(
      switchMap(dataListId => dataListId ? this.listControl.collectionService.getPagedDataListItems$(dataListId) : of(null))
    );

    this.allPagesLoaded$ = this.dataListId$.pipe(
      switchMap(dataListId => dataListId ? this.listControl.collectionService.getPagedDataListAllPagesLoaded$(dataListId) : of(false))
    );

    // Make an observable of the empty list data. Used in the empty list template to provide information about the applied filters
    this.emptyListData$ = this.dataListId$.pipe(
      switchMap(dataListId => dataListId ? this.listControl.collectionService.getPagedDataListFilters$(dataListId) : of(null)),
      map(filters => {
        if (filters) {
          return {
            filters,
            filterCount: reduce(filters, (total, pageFilter) => total + (pageFilter ? 1 : 0), 0)
          };
        } else {
          return {
            filters: null,
            filterCount: 0
          };
        }
      })
    );

    // Listen events and data changes that will reload the data
    // Wait until the next cycle to start listening because this observable can emit immediately causing template expressions to change after they were checked (ExpressionChangedAfterItHasBeenCheckedError)
    setTimeout(() => {
      this.reloadSubscription = this.dataListId$.pipe(
        filter(dataListId => !!dataListId),
        switchMap((dataListId: string) => {
          return combineLatest([
            this.listControl.collectionService.getPagedDataListFilters$(dataListId),
            this.listControl.collectionService.getPagedDataListOrder$(dataListId)
          ]);
        })
      ).subscribe(() => {
        this.loadItems();
      });

      // Listen to the infinite list being scrolled to the end in order to load more items
      this.endScrollSubscription = this.infiniteList.endScroll.subscribe(() => {
        this.loadMoreItems();
      });

      // Listen to the viewport size changing in order to load more items to fill the space
      this.viewportChangeSubscription = this.viewportRuler.change(100).subscribe(() => {
        this.checkViewportSize();
      });
    }, 0);
  }

  /** The retry event handler. Reloads the list. */
  onRetryLoad() {
    this.loadItems();
  }

  /** Reloads the list by fetching new data from the server. */
  hardReload() {
    this.loadItems(true);
  }

  /** Updates the viewport dimensions of the list and fetches new items if necessary. */
  checkViewportSize() {
    // Update the list's viewport
    this.infiniteList.checkViewportSize();

    // Grab the new page size and if it is larger than the current number of items in the list then load a new set of items with the new page size
    const oldPageSize = this.pageSize;
    const newPageSize = this.getListViewportPageSize();

    if (newPageSize > oldPageSize) {
      this.loadItems();
    }
  }

  /** Removes the active item from the key manager. */
  clearKeyManagerActiveItem() {
    this.infiniteList?.clearKeyManagerActiveItem();
  }

  /** Emulates a click on the active item of the list's key manager. */
  clickKeyManagerActiveItem() {
    this.infiniteList?.clickKeyManagerActiveItem();
  }

  /** Loads more items onto the end of the list by making a request for the next page of items. */
  loadMoreItems() {
    if (this.dataListId) {
      this.loadingMoreState.update(true);

      this.loadMoreSubscription = this.listControl.collectionService.loadMorePagedDataListItems$(this.dataListId, this.getListViewportPageSize(), undefined, {
        fetch: pageFilter => this.listControl.fetchDataListPage$(pageFilter)
      }).subscribe(() => {
        this.loadingMoreState.update(false);
      }, error => {
        this.loadingMoreState.update(false, 'Unable to load more items.', this.errorService.getErrorMessages(error));
      });
    }
  }

  /** Clears the items in the list and then loads the first page of items. */
  protected loadItems(hardReload: boolean = false) {
    if (this.dataListId) {
      this.loadingState.update(true);
      // The data is reloading making the current active item invalid. So clear the active item.
      this.infiniteList.clearKeyManagerActiveItem();

      this.loadSubscription = this.listControl.collectionService.clearPagedDataList$(this.dataListId).pipe(
        switchMap(() => this.listControl.collectionService.loadMorePagedDataListItems$(this.dataListId, this.getListViewportPageSize(), undefined, {
          fetch: pageFilter => this.listControl.fetchDataListPage$(pageFilter)
        }, {
          forceApiRequest: hardReload
        }))
      ).subscribe(() => {
        this.loadingState.update(false);
      }, error => {
        this.loadingState.update(false, 'Unable to load the list.', this.errorService.getErrorMessages(error));
      });
    }
  }

  /** Returns the height of the list's viewport. Returns zero if the list element does not exist. */
  protected getListViewportHeight(): number {
    return this.listElementRef && this.listElementRef.nativeElement ? this.listElementRef.nativeElement.offsetHeight : 0;
  }

  /**
   * Returns the number of items that fit in the viewport.
   * The page size returned is always 2 items more than can fit to ensure the viewport is filled and give a minimum of 2 items.
   */
  protected getListViewportPageSize(): number {
    const listViewportHeight = this.getListViewportHeight();

    // Grab the page size for the current viewport. Round up and default to 2 if no items can fit
    const pageSize = Math.ceil(listViewportHeight / this.infiniteList.itemHeight) + 2;

    // If this is the first time getting the page size or the page size has increased
    if (isNaN(this.pageSize) || pageSize > this.pageSize) {
      // Then store the new larger page size
      this.pageSize = pageSize;
    }

    // Always return the largest value that the page size has been. This ensures the same page size is always used when fetching data
    // This only becomes an issue when the virtual scroll viewport size changes
    return this.pageSize;
  }
}
