import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SitesState } from '@portal-core/sites/services/sites-data.service';
import { SiteStylesState } from '@portal-core/sites/services/site-styles/site-styles-data.service';
import { TrustedDomainGroupState } from '@portal-core/sites/services/trusted-domain-group/trusted-domain-group-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([SitesState, SiteStylesState, TrustedDomainGroupState])
  ]
})
export class SitesServicesModule { }
