export enum CentralPermissions {
  ManageTeamsProjects = 'ManageTeamsProjects',
  UserAdministration = 'UserAdministration',
  DeleteUser = 'DeleteUser',
  DeleteProjects = 'DeleteProjects',
  ServerManagement = 'ServerManagement',
  Purchasing = 'Purchasing',
  ManageAllLicenseKeys = 'ManageAllLicenseKeys',
  EditAllTasks = 'EditAllTasks',
  UploadNewProjects = 'UploadNewProjects',
  UploadContent = 'UploadContent',
  DownloadContent = 'DownloadContent',
  RunOrScheduleBuilds = 'RunOrScheduleBuilds',
  ManageBuilds = 'ManageBuilds',
  DeleteBuilds = 'DeleteBuilds',
  DownloadBuilds = 'DownloadBuilds',
  RunOrScheduleScans = 'RunOrScheduleScans',
  ManageScans = 'ManageScans',
  ViewResults = 'ViewResults',
  DeleteTeams = 'DeleteTeams',
  SlackIntegration = 'SlackIntegration',
  ManageOutputAnalyticsKeys = 'ManageOutputAnalyticsKeys',
  ManageSites = 'ManageSites',
  RunScansForProjects = 'RunScansForProjects',
  CreateEditFiles = 'CreateEditFiles',
  AIAssist = 'AIAssist',
  EditCode = 'EditCode',
  ManageReviews = 'ManageReviews'
}
