<form class="mc-spring-board" (ngSubmit)="onSubmit()" [formGroup]="reviewSettingsForm">
  <div class="mc-spring-board-body">
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" placeholder="Name" required>
      <mat-error>
        <mc-form-control-error-message [form]="reviewSettingsForm" controlName="name" type="mc_review_package_name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Description</mat-label>
      <textarea matInput type="text" formControlName="description" placeholder="Description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Owner</mat-label>
      <mc-autocomplete-input [multiple]="false" formControlName="ownerLicenseUserId">
        <mc-license-users-select-list mcAutocompleteList [licenseId]="licenseId" [seatTypes]="[LicenseUserSeatType.Author]"></mc-license-users-select-list>
        <ng-template mcAutocompleteItem let-licenseUserId="item">
          <mc-license-user-avatar compact [disabled]="true" [licenseUser]="licenseUserId | mcLicenseUserAsync | async" size="small" [truncate]="true"></mc-license-user-avatar>
        </ng-template>
      </mc-autocomplete-input>
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Status</mat-label>
      <mat-select placeholder="Status" formControlName="status">
        <mat-option [value]="ReviewPackageStatus.Open">{{ ReviewPackageStatus.Open | mcReviewPackageStatusText }}</mat-option>
        <mat-option [value]="ReviewPackageStatus.Closed">{{ ReviewPackageStatus.Closed | mcReviewPackageStatusText }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mc-form-field" [hidden]="!statusChangedToOpen">
      <mat-checkbox color="primary-vivid" class="mc-checkbox-wrap" formControlName="resetReviewerStatus">
        Reset reviewer statuses to {{ ReviewFileUserStatus.Todo | mcReviewFileUserStatusText }}
      </mat-checkbox>
    </div>

    <mat-form-field appearance="standard" color="primary-vivid" class="mc-form-field">
      <mat-label>Table of Contents</mat-label>
      <mc-project-file-picker-input [branchName]="reviewPackage?.CommitId" [fileFilter]="TocFileFilter" formControlName="tocPath" [projectId]="reviewPackage?.ProjectId" rootFolder="Project/TOCs">
        <ng-template mcProjectFilePickerInputItem let-path>
          <mc-toc-avatar [disabled]="true" [showAvatar]="false" [showPath]="true" size="small" [tocPath]="path" [truncate]="true"></mc-toc-avatar>
        </ng-template>
      </mc-project-file-picker-input>
      <mat-error>
        <mc-form-control-error-message [form]="reviewSettingsForm" controlName="tocPath" type="mc_files"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>